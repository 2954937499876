
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { User } from '@/store/user/types';
import { USER_GETTERS } from '@/store/user/getters';
import { StateType } from '@/types';

@Component
export default class SuspendProgram extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({required: true}) programId!: string

  comment = '';

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async submit(){
    try {
      await addDoc(collection(firestore, 'program-states'), {
        date: new Date(),
        comment: "",
        programId: this.programId,
        type: StateType.SUSPENDED,
        user: this.user.id
      })

      await updateDoc(doc(firestore, 'programs', this.programId), {
        state: StateType.SUSPENDED
      })

      this.$emit('refresh')


      this.show = false;
       this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Programma gestopt'
      })
      this.$emit('suspended')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

} 
